// Import styles for bundling
import './scss/style.scss';

// Import fonts for webpack
import IvarFineMedium from './fonts/IvarFine-Medium.woff';
import IvarFineMedium2 from './fonts/IvarFine-Medium.woff2';
import MonumentGroteskItalic from './fonts/MonumentGrotesk-Italic.woff';
import MonumentGroteskItalic2 from './fonts/MonumentGrotesk-Italic.woff2';
import MonumentGroteskRegular from './fonts/MonumentGrotesk-Regular.woff';
import MonumentGroteskRegular2 from './fonts/MonumentGrotesk-Regular.woff2';

// Import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.css';
// Configure Swiper to use modules
Swiper.use([Navigation, Pagination]);


const init = () => {

  /**
   * BUGFIX for Mobile 100vh issue. Inspired by
   * https://www.markusantonwolf.com/en/articles/solution-to-the-mobile-viewport-height-issue-with-tailwind-css
   */
  function setViewHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }

  window.addEventListener('resize', setViewHeight)
  setViewHeight()

  
  // Init Swiper sliders
  const initSwipers = () => {
    const swiperContainers = document.querySelectorAll('.swiper-container')
    if (!swiperContainers) return

    // Create Swipers for all containers found
    swiperContainers.forEach(container => {
      let instrumentList = []

      // Construct Swiper ID from post ID data attribute
      const swiperId = container.dataset.swiperId

      // Get all titles from slides, and populate .swiper-pagination with them
      const rawList = container.querySelectorAll('.swiper-slide')
      rawList.forEach(item => instrumentList.push(item.dataset.swiperSlideTitle))

      // Init new Swiper with custom ID
      const swiper = new Swiper(`.swiper-container-${swiperId}`, {
        init: false,
        autoplay: {
          delay: 3000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (instrumentList[index]) + '</span>';
          }
        }
      })
      // swiper.on('init', () => console.log(`Swiper id ${swiperId} inited`))
      swiper.init()
    })
  }
  initSwipers()


  // Cookie Consent
  const cookieStorage = {
    getItem: (item) => {
      const cookies = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {})
      return cookies[item]
    },
    setItem: (item, value) => {
      const now = new Date()
      now.setMonth(now.getMonth() + 12)
      
      document.cookie = `${item}=${value};expires=${now.toUTCString()}`
    }
  }
  
  const storageType = cookieStorage
  const consentPropertyName = 'asaf_consent'

  const shouldShowPopup = () => !storageType.getItem(consentPropertyName)
  const saveToStorage = () => storageType.setItem(consentPropertyName, true)
  
  window.onload = () => {
    const consentPopup = document.querySelector('.cookiebar')
    const acceptBtn = document.querySelector('.cookie-consent-button')

    acceptBtn.addEventListener('click', e => {
      saveToStorage(storageType)
      consentPopup.classList.add('hidden')
    })
    
    if (shouldShowPopup(storageType)) {
      setTimeout(() => {
        consentPopup.classList.remove('hidden');
      }, 1000);
    }
  }


  /**
   * Menu Toggle Logic
   */
  const menuButton = document.querySelector('.menu-btn-toggle')
  menuButton.addEventListener('click', function () {
    document.body.classList.toggle('menu-open')
  }, false)


  /**
   * Accordion Toggle Logic
   */
  document.querySelectorAll('.accordion__trigger').forEach(function(e) { 
    e.addEventListener('click', function () {
      let wrapper = e.closest('.accordion__wrapper')
      wrapper.classList.toggle('open')

      changeMaxHeight(wrapper)
    }, false)
  })

  function changeMaxHeight(parentElement) {
    // let children = parentElement.children
    // let content = children[children.length - 1]
    let content = parentElement.querySelector('.accordion__content')

    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = content.scrollHeight + 'px'
    }
  }
}

//
// When DOM is ready
//
document.addEventListener('DOMContentLoaded', init);

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}